var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"pb-2"},[_c('b-card',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"text-primary"},[_vm._v("I miei avvisi ")]),_c('b-breadcrumb',{staticClass:"ml-2"},[_c('b-breadcrumb-item',[_c('feather-icon',{attrs:{"icon":"HomeIcon","scale":"1.25","shift-v":"1.25"}})],1),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v(" I miei avvisi")])],1)],1)]},proxy:true}])},[_c('div',{staticClass:"mt-2"},[_c('h1',{staticClass:"text-primary-light text-center"},[_vm._v(" Cerchi un'auto in particolare? ")]),_c('p',{staticClass:"text-center"},[_vm._v(" In questo pannello potrai inserire i dati e le caratteristiche di "),_c('br'),_vm._v(" un'auto per ricevere una notifica appena sarà disponibile. ")]),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"md":"3"}}),_c('b-col',{attrs:{"md":"6"}},[_c('validation-observer',{ref:"announcementValidation"},[_c('validation-provider',{attrs:{"name":"Marca","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-3",attrs:{"label":"Marca"}},[_c('v-select',{attrs:{"state":errors.length > 0 ? false : null,"options":_vm.brand,"reduce":function (brand) { return brand.nome_casamadre; },"label":"nome_casamadre"},on:{"input":_vm.getCarModelByBrand},model:{value:(_vm.announcement.marca),callback:function ($$v) {_vm.$set(_vm.announcement, "marca", $$v)},expression:"announcement.marca"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Modello","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-3",attrs:{"label":"Modello"}},[_c('v-select',{attrs:{"state":errors.length > 0 ? false : null,"options":_vm.models,"reduce":function (model) { return model.modello; },"label":"modello","disabled":_vm.announcement.marca == null},model:{value:(_vm.announcement.modello),callback:function ($$v) {_vm.$set(_vm.announcement, "modello", $$v)},expression:"announcement.modello"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Anno","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-3",attrs:{"label":"Anno"}},[_c('v-select',{attrs:{"state":errors.length > 0 ? false : null,"options":_vm.years,"label":"anno"},model:{value:(_vm.announcement.anno),callback:function ($$v) {_vm.$set(_vm.announcement, "anno", $$v)},expression:"announcement.anno"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Chilometraggio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-3",attrs:{"label":"Chilometraggio"}},[_c('v-select',{attrs:{"state":errors.length > 0 ? false : null,"options":_vm.km,"label":"label","reduce":function (km) { return km.key; }},model:{value:(_vm.announcement.chilometraggio),callback:function ($$v) {_vm.$set(_vm.announcement, "chilometraggio", $$v)},expression:"announcement.chilometraggio"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Colore","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-3",attrs:{"label":"Colore"}},[_c('v-select',{attrs:{"state":errors.length > 0 ? false : null,"options":_vm.colors,"reduce":function (color) { return color.text; },"label":"text"},model:{value:(_vm.announcement.colore),callback:function ($$v) {_vm.$set(_vm.announcement, "colore", $$v)},expression:"announcement.colore"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Tipo Cambio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-3",attrs:{"label":"Tipo Cambio"}},[_c('v-select',{attrs:{"options":_vm.shift},model:{value:(_vm.announcement.tipo_cambio),callback:function ($$v) {_vm.$set(_vm.announcement, "tipo_cambio", $$v)},expression:"announcement.tipo_cambio"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Alimentazione","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-3",attrs:{"label":"Alimentazione"}},[_c('v-select',{attrs:{"options":_vm.fuel_type},model:{value:(_vm.announcement.alimentazione),callback:function ($$v) {_vm.$set(_vm.announcement, "alimentazione", $$v)},expression:"announcement.alimentazione"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}})],1),_c('div',{staticClass:"text-right mt-3"},[_c('b-button',{staticClass:"w-25",attrs:{"variant":"primary"},on:{"click":_vm.validateAnnouncement}},[_vm._v("Crea avviso")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
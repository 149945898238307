<template>
  <section class="pb-2">
    <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">I miei avvisi </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active> I miei avvisi</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </template>

      <div class="mt-2">
        <h1 class="text-primary-light text-center">
          Cerchi un'auto in particolare?
        </h1>

        <p class="text-center">
          In questo pannello potrai inserire i dati e le caratteristiche di
          <br />
          un'auto per ricevere una notifica appena sarà disponibile.
        </p>

        <b-row class="mt-3">
          <b-col md="3"></b-col>

          <b-col md="6">
            <validation-observer ref="announcementValidation">
              <validation-provider
                #default="{ errors }"
                name="Marca"
                rules="required"
              >
                <b-form-group class="mb-3" label="Marca">
                  <v-select
                    :state="errors.length > 0 ? false : null"
                    :options="brand"
                    :reduce="(brand) => brand.nome_casamadre"
                    label="nome_casamadre"
                    @input="getCarModelByBrand"
                    v-model="announcement.marca"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                name="Modello"
                rules="required"
              >
                <b-form-group class="mb-3" label="Modello">
                  <v-select
                    :state="errors.length > 0 ? false : null"
                    :options="models"
                    :reduce="(model) => model.modello"
                    label="modello"
                    :disabled="announcement.marca == null"
                    v-model="announcement.modello"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                name="Anno"
                rules="required"
              >
                <b-form-group class="mb-3" label="Anno">
                  <v-select
                    :state="errors.length > 0 ? false : null"
                    :options="years"
                    label="anno"
                    v-model="announcement.anno"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                name="Chilometraggio"
                rules="required"
              >
                <b-form-group class="mb-3" label="Chilometraggio">
                  <v-select
                    :state="errors.length > 0 ? false : null"
                    :options="km"
                    label="label"
                    :reduce="(km) => km.key"
                    v-model="announcement.chilometraggio"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                name="Colore"
                rules="required"
              >
                <b-form-group class="mb-3" label="Colore">
                  <v-select
                    :state="errors.length > 0 ? false : null"
                    :options="colors"
                    :reduce="(color) => color.text"
                    label="text"
                    v-model="announcement.colore"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                name="Tipo Cambio"
                rules="required"
              >
                <b-form-group class="mb-3" label="Tipo Cambio">
                  <v-select
                    :options="shift"
                    v-model="announcement.tipo_cambio"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                name="Alimentazione"
                rules="required"
              >
                <b-form-group class="mb-3" label="Alimentazione">
                  <v-select
                    :options="fuel_type"
                    v-model="announcement.alimentazione"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </validation-observer>
          </b-col>

          <b-col md="3"></b-col>
        </b-row>

        <div class="text-right mt-3">
          <b-button class="w-25" variant="primary" @click="validateAnnouncement"
            >Crea avviso</b-button
          >
        </div>
      </div>
    </b-card>
  </section>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BButton,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider, localize } from "vee-validate";
import { required, email } from "@validations";

import { Requests } from "@/api";

import vSelect from "vue-select";

export default {
  components: {
    BButton,
    BContainer,
    BRow,
    BCol,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BFormGroup,
    vSelect,
    BForm,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
  },

  data: () => ({
    announcement: {},
    brand: [],
    models: [],

    types: ["Usato", "Nuovo", "Km0"],
    fuel_type: [
      "Benzina",
      "Diesel",
      "Elettrica",
      "Benzina/GPL",
      "Benzina/Metano",
      "Elettrica/Benzina",
    ],

    km: [
      {
        key: "2500",
        label: "2.500 km",
      },
      {
        key: "5000",
        label: "5.000 km",
      },
      {
        key: "10000",
        label: "10.000 km",
      },
      {
        key: "20000",
        label: "20.000 km",
      },
      {
        key: "30000",
        label: "30.000 km",
      },
      {
        key: "40000",
        label: "40.000 km",
      },
      {
        key: "50000",
        label: "50.000 km",
      },
      {
        key: "60000",
        label: "60.000 km",
      },
      {
        key: "70000",
        label: "70.000 km",
      },
      {
        key: "80000",
        label: "80.000 km",
      },
      {
        key: "90000",
        label: "90.000 km",
      },
      {
        key: "100000",
        label: "100.000 km",
      },
      {
        key: "125000",
        label: "125.000 km",
      },
      {
        key: "150000",
        label: "150.000 km",
      },
      {
        key: "175000",
        label: "175.000 km",
      },
      {
        key: "200000",
        label: "200.000 km",
      },
    ],

    shift: ["Manuale", "Automatico", "Semiautomatico"],

    colors: [
      { text: "Beige", value: "beige", color: "#EBCF98" },
      { text: "Blu/Azzurro", value: "blu/azzurro", color: "#679CFF" },
      { text: "Marrone", value: "marrone", color: "#983700" },
      { text: "Bronzo", value: "bronzo", color: "#FFA16C" },
      { text: "Giallo", value: "giallo", color: "#FFF500" },
      { text: "Grigio", value: "grigio", color: "#ABA8B4" },
      { text: "Verde", value: "verde", color: "#21B015" },
      { text: "Rosso", value: "rosso", color: "#E70000" },
      { text: "Nero", value: "nero", color: "#000000" },
      { text: "Argento", value: "argento", color: "#ABA8B4" },
      { text: "Lilla", value: "lilla", color: "#EC74FF" },
      { text: "Bianco", value: "bianco", color: "#FFFFFF" },
      { text: "Arancione", value: "arancione", color: "#FB5A00" },
      { text: "Oro", value: "oro", color: "#FFB74A" },
    ],

    //Helpers

    loading: false,
    required,
    email,
  }),

  computed: {
    years: function () {
      var currentYear = new Date().getFullYear(),
        years = [];
      const startYear = 1980;

      while (startYear <= currentYear) {
        years.push(currentYear--);
      }

      return years;
    },
  },

  created() {
    localize("it");

    this.getCarBrand();
  },

  methods: {
    validateAnnouncement() {
      return new Promise((resolve, reject) => {
        this.$refs.announcementValidation.validate().then((success) => {
          if (success) {
            this.createAnnouncement();
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    async getCarBrand() {
      try {
        const response = await Requests.getCarBrand();
        this.brand = response;
      } catch (err) {
        console.debug(err);
      }
    },

    async getCarModelByBrand() {
      let data = new FormData();

      data.append("casamadre", this.announcement.marca);

      try {
        const response = await Requests.getCarModelByBrand(data);
        this.models = response;
      } catch (err) {
        console.debug(err);
      }
    },

    async createAnnouncement() {
      this.loading = true;

      let request_data = new FormData();

      request_data.append("marca", this.announcement.marca);
      request_data.append("modello", this.announcement.modello);
      request_data.append("anno_max", this.announcement.anno);
      request_data.append("km_max", this.announcement.chilometraggio);
      request_data.append("colore", this.announcement.colore);
      request_data.append("tipo_cambio", this.announcement.tipo_cambio);
      request_data.append("alimentazione", this.announcement.alimentazione);
      request_data.append(
        "id_reseller",
        JSON.parse(localStorage.getItem("user")).sub
      );
      request_data.append("id_venditore", "");

      try {
        await Requests.createAnnouncement(request_data);
        this.$swal.fire({
          title: "Avviso Creato",
          icon: "success",
          text: "Avviso creato con successo!",
          customClass: {
            title: "text-primary-light",
            confirmButton: "btn btn-primary",
          },
        });
        this.announcement = {};
        this.$refs.announcementValidation.reset();
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
